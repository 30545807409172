import { useEffect } from "react"
import { addPropertyControls, ControlType, Frame } from "framer"

type Props = {
    url: string
    delay: number
}

export function AutoRedirect({ url, delay }: Props) {
    useEffect(() => {
        const isFramerPreview =
            window.location.origin === "https://preview.framer.com"

        if (isFramerPreview) {
            const timer = setTimeout(() => {
                window.location.href = url
            }, delay)

            return () => clearTimeout(timer)
        }
    }, [url, delay])

    return (
        <Frame
            background="rgba(0, 0, 0, 0.1)"
            border="1px solid rgba(0, 0, 0, 0.2)"
            borderRadius={4}
            width="100%"
            height="100%"
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "14px",
                color: "#333",
            }}
        >
            Síða ekki til - færi þig á upphafssíðu...
        </Frame>
    )
}

AutoRedirect.defaultProps = {
    url: "https://beta.pulsmedia.is",
    delay: 3000,
}

addPropertyControls(AutoRedirect, {
    url: {
        type: ControlType.String,
        title: "URL",
        defaultValue: "https://beta.pulsmedia.is",
    },
    delay: {
        type: ControlType.Number,
        title: "Delay (ms)",
        defaultValue: 3000,
    },
})
